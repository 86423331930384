import React, { useEffect, useState } from 'react';
import { useScreen } from 'usehooks-ts';
import { globalStore } from '~/store';
import { getHash } from '~/utils';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const ScreenProperties: React.FC = () => {
  const screen = useScreen();
  const [screenData, setScreenData] = useState<any>(null);

  useEffect(() => {
    const collectScreenData = async () => {
      const screenInfo = {
        mediaSearch: `${screen.width}x${screen.height}`,
        matchMediaSearch: `${window.matchMedia('(min-width: 0px)').matches ? screen.width : 0}x${window.matchMedia('(min-height: 0px)').matches ? screen.height : 0}`,
        mediaDevice: `${window.screen.width}x${window.screen.height}`,
        deviceAspectRatio: `${window.screen.width}/${window.screen.height}`,
        aspectRatio: `${window.innerWidth}/${window.innerHeight}`,
        screen: `${window.screen.width}x${window.screen.height}`,
        avail: `${window.screen.availWidth}x${window.screen.availHeight}`,
        client: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
        inner: `${window.innerWidth}x${window.innerHeight}`,
        outer: `${window.outerWidth}x${window.outerHeight}`,
        mediaViewport: `${window.visualViewport ? window.visualViewport.width : 0}x${window.visualViewport ? window.visualViewport.height : 0}`,
        domRectViewport: `${document.documentElement.getBoundingClientRect().width}x${document.documentElement.getBoundingClientRect().height}`,
        visualViewport: `${window.visualViewport ? window.visualViewport.width : 0}x${window.visualViewport ? window.visualViewport.height : 0}`,
        colorDepth: window.screen.colorDepth,
        pixelDepth: window.screen.pixelDepth,
        devicePixelRatio: window.devicePixelRatio,
        orientationType: screen.orientation.type,
        mediaOrientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
        mediaDisplayMode: window.matchMedia('(display-mode: browser)').matches ? 'browser' : 'standalone',
        touchSupport: 'ontouchstart' in window,
        maxTouchPoints: navigator.maxTouchPoints,
      };

      const hashValue = await getHash(screenInfo);

      const screenDataInfo = {
        hash: hashValue,
        data: screenInfo,
      };

      globalStore.set({ ...globalStore.get(), screen: screenDataInfo });
      setScreenData(screenDataInfo);
    };

    collectScreenData();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Screen <span className="text-sm border px-1">{screenData?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={screenData} />
      </div>
    </div>
  );
};

export default ScreenProperties;
